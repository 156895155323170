import { template as template_338c05fb06b64a7ab9ccb454d18f7b2f } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_338c05fb06b64a7ab9ccb454d18f7b2f(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
